import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['choice', 'picker']

  connect() {
    if (this.hasChoiceTarget) {
      this.choiceDate = this.choiceTargets.find((choice) => choice.value == this.dateValue);
    }
    this.pickerSingle = $('.js-single-field');
  }

  resetPicker() {
    this.pickerTarget.value = '';
    this.pickerSingle.attr('value', '');
  }

  updateChoice(event) {
    const target = event.target;
    if (target.value != this.dateValue) {
      this.resetPicker();
    }
  }

  updateTimePicker(event) {
    if (this.choiceDate) {
      this.choiceDate.checked = true;
    }
  }

  get dateValue() {
    return "datetime";
  }
}
