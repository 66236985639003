import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.block = false;
    this.open = false;
  }

  showAll() {
    this.open = true;
    this.element.classList.add('expansive-content-show-all');
  }

  showPermanents() {
    if (this.block) {
      return ;
    }

    this.open = false;
    this.element.classList.remove('expansive-content-show-all');
  }

  toggleStatic(e) {
    e.stopPropagation();

    this.block = !this.block;
  }

  toggleButton(e) {
    e.stopPropagation();

    this.block = false;
    if (this.open) {
      this.showPermanents();
    } else {
      this.showAll();
    }
  }
}

