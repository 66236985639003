import Image from '@editorjs/image';

export default class extends Image {
  onUpload(res) {
    super.onUpload(this.uploadResponse(res));
  }

  removed() {
    const deleteFn = this.config.uploader.delete;
    if (deleteFn) {
      deleteFn(this.data.file);
    }
  }

  uploadResponse(res) {
    if(res.public_url) {
      return {
        success: true,
        file: {
          url: res.public_url,
          filename: res.filename,
          key: res.key
        }
      };
    }

    return {};
  }
}
