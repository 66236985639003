import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = ['results', 'input', 'spinner'];
  static values = {
    cities: Array,
    callId: String,
  };

  connect() {
    super.connect();
    if (localStorage.getItem('cities') === null) {
      localStorage.setItem('cities', JSON.stringify(this.citiesValue));
    }
    localStorage.removeItem('states');
    localStorage.removeItem('line2');
    this.inputTarget.addEventListener('keydown', this.handleKeyDown.bind(this));
    this.resultsTarget.addEventListener('keydown', this.handleKeyDown.bind(this));
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  disconnect() {
    super.disconnect();
    localStorage.removeItem('cities');
    localStorage.removeItem('states');
    localStorage.removeItem('line2');
    this.inputTarget.removeEventListener('keydown', this.handleKeyDown.bind(this));
    this.resultsTarget.removeEventListener('keydown', this.handleKeyDown.bind(this));
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }

  handleKeyDown(event) {
    const resultItems = this.resultsTarget.querySelectorAll('li');
    let selectedIndex = -1;

    for (let i = 0; i < resultItems.length; i++) {
      if (resultItems[i].classList.contains('bg-gray-200')) {
        selectedIndex = i;
        break;
      }
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      selectedIndex = (selectedIndex - 1 + resultItems.length) % resultItems.length;
    } else if (event.key === 'ArrowDown' || (event.key === 'Tab' && resultItems.length >= 1)) {
      event.preventDefault();
      selectedIndex = (selectedIndex + 1) % resultItems.length;
    } else if (event.key === 'Escape') {
      this.hideResults();
      return;
    }

    for (const item of resultItems) {
      item.classList.remove('bg-gray-200');
    }

    if (selectedIndex >= 0) {
      resultItems[selectedIndex].classList.add('bg-gray-200');
    }

    if (event.key === 'Enter' && selectedIndex >= 0) {
      event.preventDefault();
      this.select({ target: resultItems[selectedIndex].querySelector('label') });
    }
  }

  handleOutsideClick(event) {
    if (!this.inputTarget.contains(event.target) && !this.resultsTarget.contains(event.target)) {
      this.hideResults();
    }
  }

  hideResults() {
    this.resultsTarget.classList.add('hidden');
    this.resultsTarget.innerHTML = '';
  }

  select(e) {
    let targetElement = e.target.closest('[key]');
    this.inputTarget.value = targetElement.getAttribute('value');
    this.hideResults();
    this.spinnerTarget.classList.add('hidden');
    let addressType = targetElement.getAttribute('key');
    if (['city', 'state'].includes(addressType)) {
      let keyValue = targetElement.innerText;
      let storageKey = { city: 'states', state: 'line2' }[addressType];
      fetch(`/workspaces/bsc/support/calls/${this.callIdValue}/json_address_list?key_value=${keyValue}&address_type=${addressType}`)
        .then(response => response.json())
        .then(data => {
          let addresses = data.addresses;
          localStorage.setItem(storageKey, JSON.stringify(addresses));
        });
    }
  }

  search(e) {
    e.preventDefault();
    let query = e.target.value;
    let key = e.target.getAttribute('key');
    const storageKey = { city: 'cities', state: 'states', line2: 'line2' }[key]
    if (localStorage.getItem(storageKey) !== null) {
      this.searchAddress(query, key, storageKey);
      return;
    }

    this.spinnerTarget.classList.remove('hidden');
    this.resultsTarget.classList.add('hidden');
    this.stimulateSerialized('LocalizationsAddressReflex#search', {query, key});
    setTimeout(() => {
      this.spinnerTarget.classList.add('hidden');
      this.resultsTarget.classList.remove('hidden');
    }, 3000);
  }

  searchAddress(query, key, storageKey) {
    let storedAddresses = JSON.parse(localStorage.getItem(storageKey));
    let queryChars = query.toLowerCase().split('');
    let filteredAddresses = storedAddresses.filter(address => {
      let addressChars = address.toLowerCase().split('');
      let queryIndex = 0;
      for (let char of addressChars) {
        if (char === queryChars[queryIndex]) {
          queryIndex++;
          if (queryIndex >= queryChars.length) {
            return true;
          }
        }
      }
      return false;
    }).slice(0, 5);
    if (filteredAddresses.length > 0) {
      this.resultsTarget.classList.remove('hidden');
      this.resultsTarget.innerHTML = filteredAddresses.map(filteredAddress => {
        let highlightedAddress = filteredAddress.replace(new RegExp(`(${queryChars.join('|')})`, 'gi'), `<b class="text-highlight-blue">$1</b>`);
        return `<li class="Polaris-OptionList-Option" data-action="click->localizations--address-autocomplete#select">
          <label class="Polaris-OptionList-Option__Label" key="${key}" value="${filteredAddress}">
            ${filteredAddress}
          </label>
        </li>`;
      }).join('');
    }
  }
}
