import { Controller } from 'stimulus';
import { generateTag } from '~/lib/editorjs/variable_tool/fallback_tag';

export default class extends Controller {
  addVar(event) {
    event.preventDefault();
    const dataset = event.target.dataset;
    if (dataset && dataset.identifier) {
      const tag = this.buildTag(dataset);
      this.insertTag(tag);
    }
  }

  buildTag({identifier, context}) {
    return $(generateTag(identifier, '', context)).get(0);
  }

  deleteNoEditableTag(event, selection) {
    const range = document.createRange();

    if (selection.anchorNode !== event.target) {
      range.selectNodeContents(event.target);
      range.setEndBefore(selection.anchorNode);
    } else if (selection.anchorOffset > 0) {
      range.setEnd(event.target, selection.anchorOffset);
    } else {
      return;
    }

    range.setStart(event.target, range.endOffset - 1);
    const previousNode = range.cloneContents().lastChild;
    if (previousNode && previousNode.contentEditable === 'false') {
      range.deleteContents();
      event.preventDefault();
    }
  }

  insertTag(tag) {
    const [selection, range] = this.currentSelection;
    if (selection && range) {
      range.collapse(true);
      this.maybeFixBrRange(range);
      range.insertNode(tag);
      range.setStartAfter(tag);
      this.maybeAddEndBr(range);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  selectTag(event) {
    event.preventDefault();
    const target = event.target;
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(target);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  maybeAddEndBr(range) {
    if (!range.startContainer.querySelector('br')) {
      const br = document.createElement('br');
      range.insertNode(br);
    }
  }

  maybeDeleteTag(event) {
    const selection = window.getSelection();
    if (!selection || !selection.isCollapsed || !selection.rangeCount) {
      return;
    }

    var curRange = selection.getRangeAt(selection.rangeCount - 1);
    if (curRange.commonAncestorContainer.nodeType === 3 && curRange.startOffset > 0) {
      return;
    }

    try {
      this.deleteNoEditableTag(event, selection);
    } catch {
    // Maybe out of range
    }
  }

  maybeFixBrRange(range) {
    if (range.startContainer.tagName === 'BR') {
      range.setStartBefore(range.startContainer);
    }
  }

  onBackspace(event) {
    if (event.keyCode === 8) {
      this.maybeDeleteTag(event);
    }
  }

  setCurrentSelection(event) {
    event.preventDefault();
    const selection = window.getSelection && window.getSelection();
    this._currentSelection = selection;
    this._range = selection && selection.rangeCount && selection.getRangeAt(0);
  }

  get currentSelection() {
    return [this._currentSelection, this._range];
  }
}
