import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'inputContainer', 'placeholder', 'placeholderContainer', 'submitContainer', 'flash'];

  changeClass(element, show) {
    if (show) {
      element.classList.remove('hidden');
    } else {
      element.classList.add('hidden');
    }
  }

  click() {
    this.inputTarget.click();
  }

  disabled(event) {
    event.currentTarget.classList.add('Polaris-Button--disabled');
    this.inputTarget.classList.add('hidden');
  }

  fixedName(name) {
    if (name.length <= 35) {
      return name;
    }
    return `${name.slice(0, 17)}...${name.slice(name.length - 17)}`
  }

  onFile(file) {
    this.resetFlash()
    if (file) {
      this.placeholderTarget.textContent = this.fixedName(file.name);
      this.toggleInputs(true);
    } else {
      this.toggleInputs(false);
    }
  }

  reset(event) {
    this.onFile();
  }

  resetFlash() {
    this.flashTargets.forEach((t) => t.dispatchEvent(new Event('click')));
  }

  toggleInputs(showSubmit) {
    this.changeClass(this.placeholderContainerTarget, showSubmit);
    this.changeClass(this.inputContainerTarget, !showSubmit);
    this.changeClass(this.submitContainerTarget, showSubmit);
  }

  updateFile(event) {
    const files = event.currentTarget.files;
    const file = files && files[0];
    this.onFile(file);
  }
}
