import Table from '@editorjs/table';
import mergeVariableTool from '~/lib/editorjs/variable_tool/merge_variable_tool';

const TableTool = mergeVariableTool(Table);
export default class extends TableTool {
  rendered() {
    this.parseAndRenderTable();
  }

  save(toolsContent) {
    const clone = toolsContent.cloneNode(true);
    const table = clone.querySelector('table');
    this.tagToText(table);
    return super.save(clone);
  }

  parseAndRenderTable() {
    const cells = this._tableConstructor.htmlElement.querySelectorAll('.tc-table__inp');
    cells.forEach((cell) => {
      const template = cell.innerHTML.trim();
      if (template) {
        this.parseTemplate(template).then((res) => {
          this.setRenderTemplate(cell, res);
        });
      }
    });
  }
}
