import ApplicationController from './application_controller';
import Sortable from 'sortablejs';

export default class extends ApplicationController {
  connect() {
    super.connect();

    Sortable.create(this.element, {
      onEnd: (event) => this.reorder(event),
      handle: '.reorder-handle',
    });
  }

  reorder(event) {
    this.stimulate('Block#reorder', event.item, event.newIndex);
  }
}