import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['activeElement', 'input', 'label', 'inactiveLabel']

  connect() {
    let active = true;
    this.activeElementTargets.forEach((element) => {
      active = element.value == this.activeStatus && element.checked;
    });
    this.changeStatus(active);
  }

  activeInput(status) {
    this.inputTargets.forEach((input) => {
      input.disabled = !status;
    });
  }

  activeLabel(status) {
    this.labelTargets.forEach((label) => {
      this.touchHidden(label, status);
    });
  }

  changeStatus(status) {
    this.activeInput(status);
    this.activeLabel(status);
    this.inactiveLabel(!status)
  }

  changeStatusTarget(e) {
    const status = e.target.checked && e.target.value == this.activeStatus;
    this.changeStatus(status);
  }

  inactiveLabel(status) {
    this.inactiveLabelTargets.forEach((label) => {
      this.touchHidden(label, status);
    });
  }

  touchHidden(element, status) {
    if (status) {
      element.classList.remove('hidden');
    } else {
      element.classList.add('hidden');
    }
  }

  get activeStatus() {
    return this.data.get('activeStatus');
  }
}
