import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['container'];

  close() {
    $(this.containerTarget).hide();
  }
}
