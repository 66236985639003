import { Controller } from 'stimulus';

export default class extends Controller {
  connect () {
    super.connect();
    if (this.bulkActions.isChecked(this.element.value)) {
      this.element.checked = true;
    }
  }

  get bulkActions() {
    return this.element.closest('[data-controller~=bulk-actions]')["bulk-actions"];
  }
}