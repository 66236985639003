import { Controller } from 'stimulus';

export default class extends Controller {
  selectImage(event) {
    let image = event.currentTarget;
    this.image = image;
  }

  instantInsert(event) {
    this.confirm(event);
    const modal = this.element.closest('.modal');
    modal['plain-modal'].close();
  }

  confirm(event) {
    let imageSelectedEvent = new CustomEvent('media-picker:done', { detail: this.imageData });
    this.target.dispatchEvent(imageSelectedEvent);
  }

  get target() {
    return document.getElementById(this.element.dataset.target);
  }

  get imageData() {
    return {
      imageId: this.image.dataset.imageId,
      imageChecksum: this.image.dataset.imageChecksum,
      thumbnail: this.image.dataset.thumbnail,
      embeddedUrl: this.image.dataset.embeddedUrl
    }
  }
}