import { Controller } from 'stimulus';
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = [ 'overlay', 'parent', 'trigger' ];
  static values = { interactive: Boolean, placement: String }

  connect() {
    this.tippy = tippy(this.parentTarget, {
      content: this.overlayTarget.innerHTML,
      allowHTML: true,
      theme: 'light',
      animation: 'shift-away',
      maxWidth: 450,
      placement: this.placementValue || 'bottom',
      triggerTarget: this.triggerTargets,
      ...this.interactiveParams
    });
  }

  disconnect() {
    this.tippy.destroy();
  }

  get interactiveParams() {
    if (this.interactiveValue) {
      return {
        interactive: true,
        hideOnClick: false,
      };
    }

    return {};
  }
}
