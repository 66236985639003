import ApplicationController from './application_controller';
import $ from 'jquery';
import { animateCSS } from '~/animation';

export default class extends ApplicationController {
  static targets = [ 'container', 'window', 'backdrop' ];

  open() {
    this.maybeFixInTimeline(true);
  }

  close() {
    this.dispatchEvent('modal-closed', null, this.element);
    this.closeWithoutEvent();
  }

  closeWithoutEvent() {
    this.maybeFixInTimeline(false);
    animateCSS(this.windowTarget, 'fadeOutDown');
    animateCSS(this.backdropTarget, 'fadeOut', () => {
      this.element.remove();
    });
  }

  closeWithRedirect() {
    this.close();
    this.maybeRunVisit();
  }

  escClose(event) {
    if (event.key === 'Escape') this.close()
  }

  maybeFixInTimeline(open) {
    if (!this.inTimeline) {
      return ;
    }

    let position = 'unset';
    if (!open) {
      position = 'relative';
    }
    $('.ui-feed__item.ui-feed__item--card').css({ position });
  }

  maybeRunVisit() {
    const url = this.data.get('redirectUrl');
    if (url != '') {
      Turbo.visit(url);
    }
  }

  get inTimeline() {
    return this.data.get('inTimeline') || false;
  }
}
