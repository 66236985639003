import { Controller } from 'stimulus';

const openClass = 'open';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.refreshOpen();
  }

  refreshOpen() {
    this.open = $(this.element).hasClass(openClass);
  }

  toggle() {
    if (this.hasContainerTarget && this.containerTarget.children.length > 0) {
      this.refreshOpen();
      this.open = !this.open;
      this.toggleClass();
      this.containerTarget.classList.toggle("hidden");
    }
  }

  toggleClass(open) {
    const element = $(this.element);
    if (this.open) {
      element.addClass(openClass);
    } else {
      element.removeClass(openClass);
    }
  }
};
