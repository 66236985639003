import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { removeClass, addClass } from '~/lib/utils/class_fns';

export default class extends Controller {
  static targets = ['uppy', 'form', 'createForm'];

  uploadAll(event) {
    event.preventDefault();
    const valid = this.validateAll();
    if (valid) {
      this.dispatchEvents(event.target, 'init');
      const uppyUploads = this.uppyTargetsToPromises();
      this.uppyUploadAll(event.target, uppyUploads);
    }
  }

  dispatchEvents(target, eventName) {
    const event = new Event(`loading_button:${eventName}`, { bubbles: true });
    target.dispatchEvent(event);
  }

  validateAll() {
    return this.validateAllForms() && this.validateAllUppy();
  }

  validateAllForms() {
    return this.formTargets.every(target => {
      const valid = target.checkValidity();
      if (valid) {
        return valid;
      }
      target.reportValidity();
    })
  }

  validateAllUppy() {
    return this.uppyTargets.every(target => {
      const restrictions = target.__uppy.opts.restrictions
      const files = target.__uppy.getFiles();
      const min = restrictions.minNumberOfFiles;
      const max = restrictions.maxNumberOfFiles
      const valid = files.length >= min && files.length <= max;
      this.toggleErrUppyForm(target, valid);
      return valid;
    });
  }

  toggleErrUppyForm(target, valid) {
    const inlineErr = target.closest('form').querySelector('.uppy-error')
    if (valid) {
      addClass(inlineErr, 'hidden');
    } else {
      removeClass(inlineErr, 'hidden');
    }
  }

  uppyTargetsToPromises() {
    return this.uppyTargets.map(target =>
      new Promise((resolve, rejected) => {
        target.__uppy.upload().then((results) => {
          if (results.failed && results.failed.length) {
            return rejected();
          }

          resolve(results);
        }).catch(rejected);
      })
    );
  }

  uppyUploadAll(target, uploads) {
    Promise.all(uploads).then(() => {
      this.submitForms(target);
    }).catch(reason => {
      this.handleErr(target, reason);
    });
  }

  submitForms(target) {
    const forms = this.formTargets.map(form =>
      new Promise((resolve, rejected) => {
        form.addEventListener('ajax:success', resolve);
        form.addEventListener('ajax:error', rejected);
        Rails.fire(form, 'submit');
      })
    );

    Promise.all(forms).then(res => {
      this.handleDone(target);
    }).catch(err => {
      this.handleErr(target, err);
    });
  }

  handleDone(target) {
    Rails.fire(this.createFormTarget, 'submit');
  }

  handleErr(target, reason) {
    this.dispatchEvents(target, 'end')
  }
}
