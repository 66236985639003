import { Controller } from 'stimulus';
import $ from 'jquery';
import 'tablesorter';

export default class extends Controller {
  static targets = ['table']

  connect() {
    if (this.hasData) {
      $(this.tableTarget).tablesorter({
        selectorHeaders: '> thead tr.headers th',
        cssAsc: 'header-asc',
        cssDesc: 'header-desc'
      });
    }
  }

  get hasData() {
    return this.data.get('hasData') === 'true';
  }
}
