import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'label', 'value' ];

  connect() {
    this.update();
  }

  update() {
    this.labelTarget.textContent = this.value;
  }

  get value() {
    return this.valueTarget.value;
  }
}
