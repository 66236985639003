import { Controller } from 'stimulus';

/* Uppy */
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload';

/* Uppy locales */
import English from '@uppy/locales/lib/en_US';
import Spanish from '@uppy/locales/lib/es_ES';
import Portuguese from '@uppy/locales/lib/pt_PT';

export default class extends Controller {
  static targets = ['dropzone', 'preview'];

  static values = {
    fieldName: String,
    locale: String,
    note: String
  };

  locales = {
    es: Spanish,
    en: English,
    pt: Portuguese
  }

  connect() {
    this.uppy = Uppy({
      allowMultipleUploads: true,
      logger: Uppy.debugLogger,
      restrictions: {
        maxFileSize: 5000000,
        maxNumberOfFiles: 20,
        minNumberOfFiles: 1,
        allowedFileTypes: [
         'application/font-woff',
         'application/font-woff2',
         'application/vnd.ms-fontobject',
         'application/x-font-ttf',
         'image/*',
         'video/*',
        ],
      },
      ...this.useUppyOptions
    })

    this.uppy.use(ActiveStorageUpload, {
      directUploadUrl: this.directUploadUrl
    })

    this.uppy.use(Dashboard, {
      target: this.dropzoneTarget,
      inline: true,
      proudlyDisplayPoweredByUppy: false,
      locale: this.locale,
      note: this.noteValue,
      ...this.useDashboardOptions
    })

    this.uppy.use(ImageEditor, {
      target: Dashboard,
      id: 'ImageEditor',
      quality: 1,
      cropperOptions: {
        viewMode: 1,
        background: false,
        autoCropArea: 1,
        responsive: true,
        croppedCanvasOptions: {},
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: true,
        cropWidescreen: true,
        cropWidescreenVertical: true,
      },
    })

    this.uppy.on('file-added', (file) => {
      this.setPreview(file);
    });

    this.uppy.on('complete', this.onUppyComplete.bind(this));

    this.element.__uppy = this.uppy;
  }

  onUppyComplete(result) {
    this.clearPendingUploads();

    result.successful.forEach(file => {
      this.appendUploadedFile(file, this.fieldNameValue);
    });
    this.submit();
    this.uppy.reset();
  }

  upload(event) {
    event.preventDefault();
  }

  clearPendingUploads() {
    this.element.querySelectorAll('[data-pending-upload]').forEach(element => this.element.removeChild(element));
  }

  appendUploadedFile(file, field_name) {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', field_name);
    hiddenField.setAttribute('data-pending-upload', true);
    hiddenField.setAttribute('value', file.response.signed_id);

    this.element.appendChild(hiddenField);
  }

  submit() {
    this.form.submit();
  }

  /* Not currently used, but could be used to show preview images after uploads have finished */
  setPreview(file) {
    if (this.hasPreviewTarget) {
      let src = (file.preview) ? file.preview : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY';
      this.previewTarget.src = src;
    }
  }

  get form() {
    return this.element.closest('form');
  }

  get directUploadUrl() {
    return document.querySelector('meta[name="direct-upload-url"]').getAttribute('content');
  }

  get locale() {
    return this.locales[this.localeValue] || English;
  }

  get useDashboardOptions() {
    return {};
  }

  get useUppyOptions() {
    return {};
  }
}
