import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    count: Number,
    singular: String,
    plural: String
  };

  countValueChanged() {
    const innerHtml = this.label.replace("%{count}", this.countValue);
    this.element.innerHTML = innerHtml;
  }

  get label() {
    if (this.countValue == 1) {
      return this.singularValue;
    } else {
      return this.pluralValue;
    }
  }
}