import { Controller } from 'stimulus';
import { animateCSS } from '~/animation';

export default class extends Controller {
  openSheet() {
    $('#filter-sheet').show();
    animateCSS($('.Polaris-Sheet__Container')[0], 'slideInRight');
  }

  openExportModal() {
    $('#export-component-modal').show();
  }
}
