import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['customRoleField', 'roleField'];

  connect() {
    requestAnimationFrame(() => {
      this.toggleCustomRoleField();
    });
  }

  toggleCustomRoleField() {
    if (this.roleFieldTarget.value === 'custom') {
      this.customRoleFieldTarget.required = true;
      this.customRoleFieldTarget.disabled = false;
      this.customRoleFieldTarget.parentElement.parentElement.classList.remove('hidden');
      this.customRoleFieldTarget.selectize.enable();
    } else {
      this.customRoleFieldTarget.value = '';
      this.customRoleFieldTarget.required = false;
      this.customRoleFieldTarget.disabled = true;
      this.customRoleFieldTarget.parentElement.parentElement.classList.add('hidden');
      this.customRoleFieldTarget.selectize.disable();
    }
  }
}