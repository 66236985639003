import { Controller } from 'stimulus';
import $ from 'jquery';
import { animateCSS } from '~/animation';

export default class extends Controller {
  static targets = [ 'container', 'window' ];

  open() {
    $(this.containerTarget).show();
    animateCSS(this.windowTarget, 'slideInRight');
  }

  close() {
    if(!$(this.windowTarget).hasClass('animated')) {
      animateCSS(this.windowTarget, 'slideOutRight', () => {
        $(this.containerTarget).hide();
      });
    }
  }
}
