export function removeClass(elements, className) {
  toList(elements).forEach((ele) => {
    ele.classList.remove(className);
  });
}

export function addClass(elements, className) {
  toList(elements).forEach((ele) => {
    ele.classList.add(className);
  });
}

function toList(elements) {
  return elements.forEach ? elements : [elements];
}
