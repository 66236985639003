import ApplicationController from './application_controller';
import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = ['form']

  submitForm() {
    Rails.fire(this.formTarget, 'submit');
  }

  updateCallAndDashboard(event) {
    event.preventDefault();

    let button = event.target;
    while (button !== this.formTarget && !button.name) {
      button = button.parentNode;
    }

    const formElement = this.formTarget;
    const formData = new FormData(formElement);

    const turboFrame = document.querySelector('turbo-frame[id="form"]');
    if (turboFrame) {
      turboFrame.remove();
    }

    if (button.name === 'support_localization_call[deliverer_visibility_status]') {
      formData.set('support_localization_call[deliverer_visibility_status]', button.value);
    }

    Rails.ajax({
      type: "PATCH",
      url: formElement.action,
      data: formData,
      success: (_, status, xhr) => {
        if (xhr.status === 200) {
          const button = document.querySelector('button[data-action="click->loading#show click->search#query"]');
          if (button) {
            button.click();
          }
        }
      },
      error: (err) => {
        console.error('Failed to submit form:', err);
      }
    });
  }
}
