import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container']

  scrollToTarget(event) {
    const target = this.containerTarget;
    target.scrollIntoView({ behavior: 'smooth' });
  }
}
