import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'input', 'label']

  connect() {
    this.updateLabel();
  }

  countInputs() {
    return this.inputTargets.filter((input) => input.checked).length;
  }

  toggleContainer() {
    const toggle = 'hidden';
    let classList = this.containerTarget.classList;
    if (classList.contains(toggle)) {
      classList.remove(toggle);
    } else {
      classList.add(toggle);
    }
  }

  updateLabel() {
    const counter = this.countInputs();
    let label = counter > 1 ? this.otherLabel : this.oneLabel;

    return this.labelTarget.textContent = `${counter} ${label}`;
  }

  get oneLabel() {
    return this.data.get('oneLabel');
  }

  get otherLabel() {
    return this.data.get('otherLabel');
  }
}
