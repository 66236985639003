import { Controller } from 'stimulus';

export default class extends Controller {
  cancelEvent(event) {
    event.preventDefault();
    event.returnValue = '';
    return false;
  }

  connect() {
    this.changedForm = false;
  }

  formChanged() {
    this.changedForm = true;
  }

  leave(event) {
    if(this.changedForm) {
      if (event.type === 'beforeunload' || !window.confirm(this.msg)) {
        return this.cancelEvent(event);
      }
    }
  }

  submit() {
    this.changedForm = false;
  }

  get msg() {
    return this.data.get('msg');
  }
}
