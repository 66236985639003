import { Controller } from 'stimulus';
import $ from 'jquery';
import { animateCSS } from '~/animation';

const openClass = 'open';

export default class extends Controller {
  static targets = ['button', 'row'];
  connect() {
    for(const row of this.rowTargets) {
      $(this.collapsibleDisplay).append(row);
    }
  }

  showContent() {
    this.displayed = true;
    animateCSS(this.collapsibleDisplay, 'fadeInDown');
    $(this.collapsibleDisplay).show();
  }

  hideContent() {
    if (this.displayed) {
      this.displayed = false;
      animateCSS(this.collapsibleDisplay, 'fadeOutUp', () => {
        $(this.collapsibleDisplay).hide();
      });
      this.data.set('displayed', false);
    }
  }

  toggleContent() {
    if (this.displayed) {
      this.hideContent();
    } else {
      this.showContent();
    }
  }

  get collapsibleDisplay() {
    return $(this.element).next('tbody').get(0);
  }
}
