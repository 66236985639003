import UppyController from './uppy_controller.js';
import Spanish from '@uppy/locales/lib/es_ES';

export default class extends UppyController {
  static values = {
    formId: String,
    single: Boolean,
    resize: Boolean,
    preview: Boolean,
    customUploadText: Boolean
  }

  onUppyComplete(result) {
    this.clearPendingUploads();

    result.successful.forEach(file => {
      this.appendUploadedFile(file, this.fieldNameValue);
    });
  }

  setPreview(file) {
    setTimeout(() => {
      if (this.hasPreviewValue) {
        let fileElement = this.element.querySelector(`[id="uppy_${file.id}"]`);

        if (fileElement) {
          let actionWrapper = fileElement.querySelector('.uppy-Dashboard-Item-actionWrapper');
          if (actionWrapper) {
            let previewLink = document.createElement('a');
            previewLink.href = URL.createObjectURL(file.data);
            previewLink.target = '_blank';
            previewLink.classList.add('ml-3');
            let icon = document.createElement('i');
            icon.classList.add('fas', 'fa-eye', "text-primary", "fa-lg");
            previewLink.appendChild(icon);
            actionWrapper.appendChild(previewLink);
          }
        }
      }
    }, 10);
  }

  get useDashboardOptions() {
    return {
      width: this.resizeValue ? 400 : 500,
      height: this.resizeValue ? 130 : 200,
      hideUploadButton: true,
      hideProgressAfterFinish: true,
    }
  }

  get useUppyOptions() {
    const max = this.singleValue ? 1 : 5;
    console.log(this.formIdValue);
    return {
      id: this.formIdValue,
      restrictions: {
        maxFileSize: 5000000,
        maxNumberOfFiles: max,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'application/pdf'],
      },
    }
  }

  get directUploadUrl() {
    return document.querySelector('meta[name="direct-upload-url"]').getAttribute('content').replace("assets/", "delivery_assets/")
  }

  get locale() {
    if (!this.customUploadTextValue) {
      return super.locale;
    }

    const customTexts = {
      es: "Da click aquí para adjuntar archivo",
      // We can add more languages here, for now only Spanish is imported.
    };

    const customText = customTexts[this.localeValue];

    return customText ? {
      ...this.locales[this.localeValue],
      strings: {
        ...this.locales[this.localeValue].strings,
        browse: customText,
        dropPaste: "%{browse}",
      }
    } : super.locale;
  }
}
