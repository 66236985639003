import engine from '~/lib/editorjs/variable_tool/liquid_register';

export default function mergeVariableTool(tool) {
  class MergeVariableToolWithBase extends tool {
    maybeFixBrAtEnd(parent) {
      const childs = parent.querySelectorAll('.cd-variable-wrap');
      if (childs.length) {
        const last = childs[childs.length - 1];
        if (!last.parentNode.querySelector('br')) {
          last.parentNode.appendChild(document.createElement('br'));
        }
      }
    }

    merge(data) {
      super.merge(data);
      this.renderVariableTag();
    }

    parseTemplate(template) {
      return engine.parseAndRender(template);
    }

    rendered() {
      if (this._element && this.data.text) {
        this.renderVariableTag();
      }
    }

    renderVariableTag() {
      this.parseTemplate(this.data.text).then((res) => {
        this.setRenderTemplate(this._element, res);
      });
    }

    setRenderTemplate(element, render, withBr = true) {
      element.innerHTML = render;
      const {textContent} = element;

      if (!element.querySelector('br') && textContent !== '' && withBr) {
        element.appendChild(document.createElement('br'));
      } else if(textContent !== '') {
        this.maybeFixBrAtEnd(element);
      }
    }

    save(toolsContent) {
      const clone = this.tagToText(toolsContent.cloneNode(true));
      return super.save(clone);
    }

    tagToText(content) {
      content.childNodes.forEach((ch) => {
        if (ch.dataset && ch.dataset.identifier) {
          ch.innerText = this.templateText(ch.dataset);
        } else if (ch.childNodes.length) {
          this.tagToText(ch);
        }
      });
      return content;
    }

    templateText(dataset) {
      const fallback = this.valueOrEmptyText(dataset.fallback);
      const context = this.valueOrEmptyText(dataset.context);
      const label = this.valueOrEmptyText(dataset.label);
      return `{% variableTag '${dataset.identifier}', '${fallback}', '${context}' %}`;
    }

    valueOrEmptyText(value) {
      return value || '';
    }
  }

  return MergeVariableToolWithBase;
}
