import { Notyf } from 'notyf';

export function successToast(message) {
  const notyf = new Notyf({
    duration: 5000,
    ripple: false,
     position: {
      x: 'center',
      y: 'bottom',
    },
  });
  notyf.success(message);
}

export function errorToast(message) {
  const notyf = new Notyf({
    duration: 5000,
    ripple: false,
     position: {
      x: 'center',
      y: 'bottom',
    },
  });
  notyf.error(message);
}