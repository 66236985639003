import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['iframe', 'container'];

  connect() {
    super.connect();
    this.listener = (event) => this.reloadPreview(event);
    document.addEventListener('preview-reload', this.listener);
  }

  disconnect() {
    document.removeEventListener('preview-reload', this.listener);
  }

  changeScreenToDesktop() {
    this.resetScreenClasses();
    this.element.classList.add('desktop');
  }

  changeScreenToTablet() {
    this.resetScreenClasses();
    this.element.classList.add('tablet');
  }

  changeScreenToMobile() {
    this.resetScreenClasses();
    this.element.classList.add('mobile');
  }

  changeRenderToWeb() {
    this.resetRenderClasses();
    this.element.classList.add('web');
    const url = new URL(this.iframeTarget.src);
    url.searchParams.delete('inline');
    this.iframeTarget.src = url.toString();
  }

  changeRenderToEmail() {
    this.resetRenderClasses();
    this.element.classList.add('email');
    const url = new URL(this.iframeTarget.src);
    url.searchParams.set('inline', 'true');
    this.iframeTarget.src = url.toString();
  }

  openPreviewWindow() {
    window.open(this.iframeTarget.src, '_blank');
  }

  resetScreenClasses() {
    this.element.classList.remove('desktop');
    this.element.classList.remove('tablet');
    this.element.classList.remove('mobile');
  }

  resetRenderClasses() {
    this.element.classList.remove('web');
    this.element.classList.remove('email');
  }

  reloadPreview(_event) {
    this.iframeTarget.src += '';
  }
}