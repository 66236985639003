import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  connect() {
    this.validatePostalCode = debounce(this._validatePostalCode, 500).bind(this);
  }

  _validatePostalCode(event) {
    event.preventDefault();
    const postalCode = event.target.value;
    if (!postalCode) {
      return ;
    }
    const actionUrl = event.target.dataset.validator;
    const state = event.target.dataset.state;
    const url = `${actionUrl}?address[postal_code]=${postalCode}&address[state]=${state}`;
    const opts = {
      url: decodeURIComponent(url),
      updatePushState: false,
      onlyKeys: ['postal-code-banner', 'postal-code-input-status', 'postal-code-group-fields'],
    };

    Page.refresh(opts);
  }
}
