import { Tokenizer, evalToken } from 'liquidjs';

let mapLabelsValues = {};

function generateKey(context, identifier) {
  return `${context}.${identifier}`;
}

function mapLabels(key) {
  if (!$.isEmptyObject(mapLabelsValues)) {
    return mapLabelsValues[key];
  }

  $('.variable-list li').each((index, ele) => {
    const key = generateKey(ele.dataset.context, ele.dataset.identifier);
    mapLabelsValues[key] = ele.dataset.label;
  });

  return mapLabelsValues[key];
}

function labelFromMap(context, identifier) {
  const key = generateKey(context, identifier);
  return mapLabels(key) || identifier;
}

export function generateTag(identifier, fallback, context) {
  return `<span
    class="cd-variable-wrap Polaris-Badge"
    data-identifier="${identifier}"
    data-context="${context || ''}"
    data-fallback="${fallback}"
    data-action="click->input-editor-tag#selectTag"
    contenteditable="false"><span class="cd-variable">${labelFromMap(context, identifier)}</span></span>`;
}

export function fallbackTag(Liquid) {
  this.registerTag('variableTag', {
    parse: (tagToken) => {
      this.args = this.args || [];
      let args = [];
      const tokenizer = new Tokenizer(tagToken.args);
      tokenizer.skipBlank();

      while (!tokenizer.end()) {
        const value = tokenizer.readValue();
        if (value) {
          args.push(value);
        }
        tokenizer.readTo(',');
      }

      this.args.push(args);
    },

    render: (scope, hash) => {
      const args = this.args.shift();
      const identifier = evalToken(args[0], scope);
      const fallback = evalToken(args[1], scope);
      const context = evalToken(args[2], scope);

      return generateTag(identifier, fallback, context);
    }
  });
}
