import { Controller } from 'stimulus';
import $ from 'jquery';
import { animateCSS } from '~/animation';

export default class extends Controller {
  static targets = [ 'container', 'window', 'backdrop' ];

  open(event) {
    event.preventDefault();
    $(this.containerTarget).show();
    animateCSS(this.windowTarget, 'fadeInUp');
    animateCSS(this.backdropTarget, 'fadeIn');
    this.maybeFixInTimeline(true);
  }

  close(event) {
    event.preventDefault();
    this.maybeFixInTimeline(false);
    animateCSS(this.windowTarget, 'fadeOutDown');
    animateCSS(this.backdropTarget, 'fadeOut', () => {
      $(this.containerTarget).hide();
    });
  }

  maybeFixInTimeline(open) {
    if (!this.inTimeline) {
      return ;
    }

    let position = 'unset';
    if (!open) {
      position = 'relative';
    }
    $('.ui-feed__item.ui-feed__item--card').css({ position });
  }

  get inTimeline() {
    return this.data.get('inTimeline') || false;
  }
}
