import { Controller } from 'stimulus';
import Popper from 'popper.js';
import { animateCSS } from '~/animation';

export default class extends Controller {
  static targets = [ 'button', 'overlay' ];

  listenForOutsideClicks() {
    const self = this;

    $(document).on(`click.${self.eventNamespace}`, (e) => self.hideIfOutsideClick(self, e));
  }

  hideIfOutsideClick(controller, e) {
    const target = $(e.target);
    if (target.is(this.buttonTarget) ||
      target.is(this.overlayTarget) ||
      $.contains(this.buttonTarget, target[0])) {
      e.preventDefault();
    } else {
      if (!this.targetKeepOpen(target)) {
        controller.hide();
        $(document).off(`click.${this.eventNamespace}`);
      }
    }
  }

  showOverlay() {
    this.show();
    this.listenForOutsideClicks();
  }

  show() {
    new Popper(this.buttonTarget, this.overlayTarget, {
      placement: this.placement
    });
    $(this.overlayTarget).show();
    animateCSS(this.overlayTarget, 'fadeIn');
  }

  hide() {
    animateCSS(this.overlayTarget, 'fadeOut', () => {
      $(this.overlayTarget).hide();
    });
  }

  targetKeepOpen(target) {
    if (this.keepOpen) {
      return $.contains(this.overlayTarget, target[0]);
    }
  }

  get eventNamespace() {
    const name = $(this.buttonTarget).text().replace(/[_\W]+/g, '');
    return $.trim(name).toLowerCase();
  }

  get keepOpen() {
    return this.data.get('keepOpen') === 'true';
  }

  get placement() {
    return this.data.get('placement') || 'bottom';
  }
}