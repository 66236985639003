import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [ 'value' ];

  static values = {
    multipleItems: Boolean,
    includeBlank: Boolean,
    create: Boolean,
    placeholder: String,
    optionCreateLabel: String
  }

  connect() {
    this.selectize = $(this.valueTarget).selectize({
      dropdownParent: 'body',
      allowEmptyOption: !this.includeBlankValue,
      maxItems: this.multipleItemsValue ? null : 1,
      create: this.createValue,
      createOnBlur: this.createValue,
      render: {
        option_create: (d, e) => this.renderOptionCreate(d, e),
      },
      placeholder: this.placeholderValue
    });
    this.selectize.on('change', () => {
      this.valueTarget.dispatchEvent(new CustomEvent('select:change'));
      $(this.valueTarget).trigger('click');
    });
  }

  renderOptionCreate(data, escape) {
    return `<div class="create">${this.optionCreateLabel} <strong>${escape(data.input)}</strong>&hellip;</div>`;
  }

  get optionCreateLabel() {
    return this.optionCreateLabelValue || 'Agregar';
  }

  get value() {
    return this.valueTarget.value;
  }
}
