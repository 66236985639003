import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['form', 'label', 'bar'];

  connect() {
    this.allFields = $(this.formTarget).find(':input[required]:visible');
    this.allFields.on('change', this.updateProgress.bind(this));
    this.total = this.totalFields();
    this.updateProgress();
  }

  countFields() {
    return this.
      allFields.
      filter((_i, field) => {
        if (field.type === 'radio') {
          return field.checked;
        }
        return field.value !== '';
      }).length;
  }

  totalFields() {
    const exist = {};
    return this.allFields.filter((_i, field) => {
      if (!exist[field.name]) {
        exist[field.name] = true;
        return true;
      }
    }).length;
  }

  updateProgress() {
    const progress = Math.round(this.countFields() * 100 / this.total);
    const width = `${progress}%`;
    $(this.labelTarget).text(width);
    $(this.barTarget).css({ width });
  }
}
