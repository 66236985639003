import { Controller } from 'stimulus';
import $ from 'jquery';

const stepValue = 0.25;

export default class extends Controller {
  static targets = ['container', 'loading'];

  connect() {
    this.events();
  }

  clearTimer() {
    clearInterval(this.timer);
    $(this.containerTarget).hide();
    this.stepValue = stepValue;
  }

  disconnect() {
    this.clearTimer();
  }

  events() {
    $(document).on('turbograft:remote:init', this.initTimer.bind(this));
    $(document).on('turbograft:remote:always', this.clearTimer.bind(this));
  }

  initTimer() {
    this.stepValue = stepValue;
    $(this.containerTarget).show();
    this.timer = setInterval(this.step.bind(this), 400);
  }

  step() {
    this.stepValue += stepValue;
    $(this.loadingTarget).css({transform: `scaleX(${this.stepValue})`});
    if (this.stepValue > 1) {
      this.clearTimer();
    }
  }
}
