// source https://github.com/codex-team/editor.js/blob/be6f9b78f265c3d553c799c99e59c9916bf29e17/src/components/selection.ts
function expandToTag(element) {
  const selection = window.getSelection();

  selection.removeAllRanges();
  const range = document.createRange();
  range.selectNodeContents(element);
  selection.addRange(range);
}

function findParentTagOrCurrent(tagName, className = null, searchDepth = 10) {
  const selection = window.getSelection();
  let parentTag = null;

  if (!selection || !selection.anchorNode || !selection.focusNode) {
    return ;
  }

  const boundNodes = [
    selection.anchorNode,
    selection.focusNode
  ];

  boundNodes.forEach((parent) => {
    let searchDepthIterable = searchDepth;

    while (searchDepthIterable > 0 && parent.parentNode) {
      if (parent.tagName === tagName.toUpperCase()) {
        parentTag = parent;

        if (className && parent.classList && !parent.classList.contains(className)) {
          parentTag = null;
        }

        if (parentTag) {
          break;
        }
      }

      parent = parent.parentNode;
      searchDepthIterable--;
    }
  });

  if (!parentTag && selection.focusNode && selection.focusNode.querySelector) {
    return selection.focusNode.querySelector(tagName);
  }

  return parentTag;
}

function unwrap(tagWrapper) {
  expandToTag(tagWrapper);

  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  let unWrapped = range.extractContents();

  tagWrapper.parentNode.removeChild(tagWrapper);

  range.insertNode(unWrapped);

  selection.removeAllRanges();
  selection.addRange(range);
}

function wrap(range, tagName) {
  let tag = document.createElement(tagName);

  tag.appendChild(range.extractContents());
  range.insertNode(tag);

  expandToTag(tag);
}

export default class SurroundTag {
  constructor(commandName) {
    this.commandName = commandName;
    this.commands = {
      'italic': 'i',
      'bold': 'b'
    };
  }

  range() {
    const selection = window.getSelection();
    const range = selection && selection.rangeCount ? selection.getRangeAt(0) : null;
    return range;
  }

  tagName() {
    return this.commands[this.commandName];
  }

  toggle() {
    const range = this.range();
    const tagName = this.tagName(this.commandName);

    if (!range || !tagName) {
      return ;
    }

    const tagWrapper = findParentTagOrCurrent(tagName);

    if (tagWrapper) {
      unwrap(tagWrapper);
    } else {
      wrap(range, tagName);
    }
  }

  get available() {
    return !!this.tagName();
  }
}
