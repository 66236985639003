import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    ids: Array
  }

  checkIds(event) {
    this.dispatchEvent('check-ids', { ids: this.idsValue });
  }
}