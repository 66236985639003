import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reset'];

  maybeResetImporters(event) {
    const index = event.currentTarget.dataset.index;
    if (index) {
      const current = parseInt(index);
      this.resetTargets.forEach((target, i) => {
        if (current != i) {
          target.dispatchEvent(new Event('click'));
        }
      });
    }
  }
}
