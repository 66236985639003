import { Controller } from 'stimulus';

const minLength = 6;
const hiddenClass = 'hidden';

export default class extends Controller {
  static targets = ['password', 'passwordConfirmation', 'passwordLabel', 'passwordConfirmationLabel', 'confirmationLabel', 'submit']

  changeInput(event) {
    const field = `${this.fieldFromEvent(event)}LabelTarget`;
    const label = this[field];
    if (!label) {
      return ;
    }

    if (event.target.value.length >= minLength) {
      label.classList.add(hiddenClass);
    } else {
      label.classList.remove(hiddenClass);
    }
  }

  confirmInput() {
    const password = this.passwordTarget.value;
    const passwordConfirmation = this.passwordConfirmationTarget.value;
    const label = this.confirmationLabelTarget;

    if(password.length >= minLength && passwordConfirmation.length >= minLength) {
      if (password != passwordConfirmation) {
        label.classList.remove(hiddenClass);
        this.valid = false;
      } else {
        label.classList.add(hiddenClass);
        this.valid = true;
      }
    }
  }

  fieldFromEvent(event) {
    const target = event.target;
    return target.dataset.field;
  }

  submit(event) {
    if (!this.valid) {
      event.preventDefault();
    }
  }
}
