import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'container' ];

  static values = {
    notClose: Boolean
  }

  connect() {
    if (this.notCloseValue) {
      return;
    }

    setTimeout(() => {
      this.close();
    }, 4000)
  }

  close() {
    this.containerTarget.remove();
  }
}
