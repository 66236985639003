import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    filtersToIdsUrl: String
  }

  async checkAllInFilters(event) {
    event.preventDefault();
    try {
      const response = await fetch(this.filtersToIdsUrlValue);
      const json = await response.json();
      this.dispatchEvent('check-ids', { ids: json.ids });
   } catch (e) {
      console.error(e);
    }
  }
}