import ApplicationController from './application_controller';
import { addClass } from '~/lib/utils/class_fns';

export default class extends ApplicationController {
  static targets = ['users', 'submit'];

  connect() {
    super.connect();
    this.stimulate('AgenciesReflex#load_agencies');
  }

  changeAgency(event) {
    if (this.usersTarget.selectize) {
      this.usersTarget.selectize.clear();
      this.usersTarget.selectize.disable();
    }

    this.stimulate(
      'AgenciesReflex#change_agency',
      event.target.value
    );
  }

  onSubmit() {
    addClass(this.submitTarget, 'Polaris-Button--disabled')
  }
}
