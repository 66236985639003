import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import { removeClass, addClass } from '~/lib/utils/class_fns';

export default class extends Controller {
  static targets = ['previewFrame', 'form', 'reassignationTime', 'selectionTypeInput'];
  static values = {
    previewUrl: String,
    actionUrl: String
  };

  connect() {
    this.actionUrlValue = this.formTarget.action;
    setTimeout(() => {
      this.cleanCheckboxes();
      this.preview();
    }, 1000);
    this.showReassignationTime();
  }

  // TEMPORAL HACK
  // This is a temporal hack to fix the issue with checkboxes being interpreted as checked due to missing
  // the `checked` attribute. This is a known issue with browsers and the `checked` attribute.
  cleanCheckboxes() {
    this.formTarget.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = checkbox.hasAttribute('checked');
    });
  }

  addTurboAttributes() {
    this.formTarget.setAttribute('data-turbo-frame', 'preview');
    this.formTarget.setAttribute('data-turbo-action', 'replace');
  }

  removeTurboAttributes() {
    this.formTarget.removeAttribute('data-turbo-frame');
    this.formTarget.removeAttribute('data-turbo-action');
  }

  submit() {
    this.enableMethodInput();
    this.removeTurboAttributes();
    this.formTarget.action = this.actionUrlValue;
    this.submitForm();
  }

  preview() {
    this.disableMethodInput();
    this.addTurboAttributes();
    this.formTarget.action = this.previewUrlValue;
    this.submitForm();
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit');
  }

  disableMethodInput() {
    if (this.methodInput) {
      this.methodInput.disabled = true;
    }
  }

  enableMethodInput() {
    if (this.methodInput) {
      this.methodInput.disabled = false;
    }
  }

  updateCurrentOrDate(event) {
    const parentNode = event.target.closest('.date-with-current');
    const fieldContainers = parentNode.querySelectorAll('.Polaris-TextField');

    if (event.target.checked) {
      fieldContainers.forEach(fieldContainer => {
        const input = fieldContainer.querySelector('input')
        const skipDisableInput = input && input.dataset.skipDisableInput;
        if (!skipDisableInput) {
          addClass(fieldContainer, 'Polaris-Button--disabled');
        }
        this.resetDateInputs(fieldContainer.querySelectorAll('input'));
      });
    } else {
      fieldContainers.forEach(fieldContainer => {
        removeClass(fieldContainer, 'Polaris-Button--disabled');
        this.resetDateInputs(fieldContainer.querySelectorAll('input'));
      });
    }
  }

  resetDateInputs(inputs) {
    inputs.forEach(input => {
      input.value = '';
      const pick = input._flatpickr;
      if (pick) {
        pick.clear();
      }
    });
  }

  showReassignationTime() {
    if (this.selectionTypeInputTarget.value === 'closed' || this.selectionTypeInputTarget.value === 'any') {
      removeClass(this.reassignationTimeTarget, 'hidden');
    } else {
      this.reassignationTimeTarget.querySelector('input').value = '';
      addClass(this.reassignationTimeTarget, 'hidden');
    }
  }

  get methodInput() {
    return this.formTarget.querySelector('input[name="_method"]');
  }
}
