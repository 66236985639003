import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['commentInput', 'otherReasonInput'];

  toggleNextOptions(event) {
    if (event.target.value === '') {
      this.commentInputTarget.disabled = true;
    } else {
      this.commentInputTarget.disabled = false;
    }


    if (event.target.value === 'other') {
      this.otherReasonInputTarget.classList.remove('hidden');
      this.otherReasonInputTarget.querySelector('input').required = true;
    } else {
      this.otherReasonInputTarget.classList.add('hidden');
      this.otherReasonInputTarget.querySelector('input').required = false;
    }
  }
}
