import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hidden', 'label'];

  initializeConfig() {
    const data = this.config;
    if (data.key) {
      this.hiddenTarget.value = data.key;
      this.labelTarget.textContent = data.label;
    }
  }

  setColumn(event) {
    this.configureColumn = event.target.closest('button').dataset;
    this.initializeConfig();
  }

  get config() {
    return this.configureColumn || {};
  }
}
