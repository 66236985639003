import ApplicationController from './application_controller';

export default class extends ApplicationController {
  close() {
    this.element.remove();
    this.modalTurboFrame.src = null;
  }
  
  escClose(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
  
  get modalTurboFrame() {
    return document.querySelector('turbo-frame[id="modal"]');
  }
}