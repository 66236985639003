import { Controller } from 'stimulus';
import { animateCSS } from '~/animation';

export default class extends Controller {
  static targets = [ 'element' ];

  addAnimationClassToNextClass() {
    const className = event.target.dataset.animationClass;
    const defaultClass = 'Polaris-Card:first-child';
    const nextClass = event.target.dataset.animationNextClass || defaultClass;
    const card = this.elementTarget.querySelector(`.${nextClass}`);
    if (className && card) {
      animateCSS(card, className);
    }
  }
}
