import { Controller } from 'stimulus';
import $ from 'jquery';

const openClass = 'open';

export default class extends Controller {
  static targets = ['content', 'innerContent', 'button'];

  connect() {
    this.refreshOpen();
  }

  refreshOpen() {
    this.open = $(this.element).hasClass(openClass);
  }

  toggle() {
    this.refreshOpen();
    this.open = !this.open;
    this.toggleClass();

    const height = this.open ? $(this.innerContentTarget).height() : 0;
    const deg = this.open ? 180 : 0;

    $(this.contentTarget).animate({ height }, 300);
  }

  toggleClass(open) {
    const element = $(this.element);
    if (this.open) {
      element.addClass(openClass);
    } else {
      element.removeClass(openClass);
    }
  }
}
