import ApplicationController from './application_controller';
import { nextFrame } from '~/lib/utils/timing_helpers';
import { differenceWith, uniq } from 'lodash';

export default class extends ApplicationController {
  static targets = ['checkboxAll', 'checkbox', 'menu', 'selectedIdsField', 'selectedCount'];
  static values = {
    ids: Array
  };

  initialize () {
    super.initialize();
    this.toggleAll = this.toggleAll.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  connect () {
    super.connect();
    this.idsValue = [];
    this.refresh();
  }

  resourceChecked(event) {
    this.refresh();
  }

  toggleAll(e) {
    e.preventDefault();
    const checked = this.checked.length != this.checkboxTargets.length;
    this.checkAllCheckboxes(checked);
    this.refresh();
  }

  refresh() {
    this.refreshAllCheckbox();
    this.update();
  }

  refreshAllCheckbox() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;
    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate = this.idsNotListed.length > 0 || (checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount);
  }

  refreshCheckboxes() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.isChecked(checkbox.value);
    });
  }

  update() {
    this.updateIdsValue();
    this.updateSelectedIds();
    this.updateMenu();
    this.updateCount();
  }

  updateCount() {
    this.selectedCountTarget.dataset.bulkActionsCountCountValue = this.selectedCount;
  }

  updateIdsValue() {
    let ids = [];
    this.idsNotListed.forEach((storedId) => ids.push(storedId));
    this.checked.forEach((checkbox) => ids.push(checkbox.value));
    this.idsValue = ids;
  }
 
  updateMenu() {
    this.menuTarget.open = this.selectedCount > 0;
  }

  updateSelectedIds() {
    this.selectedIdsFieldTargets.forEach(field => field.value = this.idsValue);
  }

  reset(event) {
    this.checkboxAllTarget.checked = false;
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false);
    this.idsValue = [];
    this.updateSelectedIds();
    this.updateMenu();
    this.refresh();
  }

  checkAll() {
    this.checkAllCheckboxes(true);
    this.refresh();
  }

  checkAllCheckboxes(checked) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = checked
    });
  }

  checkIds(event) {
    const checkIds = event.detail.ids;
    const currentIds = this.idsValue;
    this.idsValue = uniq(currentIds.concat(checkIds));
    this.refreshCheckboxes();
    this.refresh();
  }

  async resetAsync(event) {
    if (event) {
      if ("open" in event.target && event.target.open) return;
      if (event.type == "submit") await nextFrame();
    }
    this.reset(event);
  }

  isAllChecked(id) {
    const selectedIds = this.selectedIdsFieldTargets[0].value.split(",");
    return selectedIds.includes(id);
  }

  isChecked(id) {
    return this.idsValue.includes(id);
  }

  get selectedCount() {
    return this.idsValue.length;
  }

  get idsNotListed() {
    return differenceWith(this.idsValue, this.checkboxIds, _.isEqual);
  }

  get checkboxIds() {
    return this.checkboxTargets.map(checkbox => checkbox.value);
  }

  get checked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get unchecked() {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }
}