import { Controller } from 'stimulus';
import EditorJS from '@editorjs/editorjs';
import { DirectUpload } from '@rails/activestorage';
import configure from '~/lib/editorjs/config';
import sendBeacon from '~/lib/utils/send_beacon';
import appendElement from '~/lib/utils/append_element';

export default class extends Controller {
  static targets = ['input', 'editor', 'files', 'variablesList'];

  addButtonVariable() {
    appendElement('.ce-toolbar__actions-buttons', this.variablesListTarget);
  }

  connect() {
    this.editor = new EditorJS(configure(this));
    this.editor.isReady.then(this.addButtonVariable.bind(this));
    this.inputBlobFiles = [];
  }

  change() {
    this.editor.save().then(this.save.bind(this));

    const event = document.createEvent('Event');
    event.initEvent('change', true, true);
    this.inputTarget.dispatchEvent(event);
  }

  deleteFile(blob) {
    const url = this.uploadPathAction(`${blob.key}/${blob.filename}`);
    return $.ajax({ url, type: 'delete' });
  }

  deleteFileWithBeacon(blob) {
    const url = this.uploadPathAction(`delete/${blob.key}/${blob.filename}`);
    sendBeacon(url);
  }

  disconnect() {
    this.inputBlobFiles.map(this.deleteFileWithBeacon.bind(this));
    this.inputBlobFiles = [];
  }

  editorPathAction(action) {
    return `${this.editorPath}/${action}`;
  }

  pushHiddenFiles(blob) {
    if (blob.signed_id) {
      this.inputBlobFiles.push(blob);
      const files = (this.filesTarget.value || '').split(',');
      files.push(blob.signed_id);
      this.filesTarget.value = files.filter((f) => f).join(',');
    }
  }

  save(data) {
    this.inputTarget.value = JSON.stringify(data);
  }

  submit() {
    this.inputBlobFiles = [];
  }

  uploadByFile(upload) {
    return new Promise((resolve, reject) => {
      upload.create((err, res) => {
        if (err) {
          return reject(err);
        }

        this.pushHiddenFiles(res);
        resolve(res);
      });
    });
  }

  uploadByUrl(data) {
    return $.post(this.uploadPathAction('url'), data).then((res) => {
      this.pushHiddenFiles(res);
      return res;
    });
  }

  uploadPathAction(action) {
    return `${this.directUploadPath}/${action}`;
  }

  get directUploadPath() {
    return this.data.get('direct-upload-path');
  }

  get editorPath() {
    return this.data.get('editor-path');
  }

  get initialData() {
    try {
      return JSON.parse(this.inputTarget.value);
    } catch {
      return {};
    }
  }

  get linkFetch() {
    return this.editorPathAction('fetch_url_data');
  }

  get uploaderConfig() {
    return {
      uploadByFile: (file) => {
        const upload = new DirectUpload(file, this.uploadPathAction('file'));
        return this.uploadByFile(upload);
      },
      uploadByUrl: (url) => {
        const data = { 'direct_upload_file': { url } };
        return this.uploadByUrl(data);
      },
      delete: (data) => {
        if (data.key) {
          return this.deleteFile(data).then();
        }
      }
    };
  }
}
