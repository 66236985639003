import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'container'];

  connect() {
    this.scrollElement.addEventListener('scroll', this.load.bind(this));
  }

  disconnect() {
    this.scrollElement.removeEventListener('scroll', this.load.bind(this));
  }
  
  load() {
    if (this.hasButtonTarget) {
      const top = this.buttonTarget.getBoundingClientRect().top;
      if (top < $(this.scrollElement).innerHeight() + 500) {
        this.buttonTarget.click();
      }
    }
  }
  
  get scrollElement() {
    if (this.hasContainerTarget) {
      return this.containerTarget;
    } else {
      return window;
    }
  }
}