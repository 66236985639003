import { Controller } from 'stimulus';
import { removeClass, addClass } from '~/lib/utils/class_fns';

export default class extends Controller {
  static targets = ['choice', 'picker', 'inputParent']

  connect() {
    this.pickerSingle = this.inputParentTarget.querySelector('.js-single-field');
  }

  updateChoice(event) {
    this.pickerInput = this.pickerInputOrDefault();
    this.touchDateField(event);
  }

  touchDateField(event) {
    if (event.target.checked) {
      removeClass(this.inputParentTarget, 'hidden');
      this.pickerInput.readOnly = false;
      this.pickerInput.required = true;
    } else {
      this.resetPicker();
      addClass(this.inputParentTarget, 'hidden');
      this.pickerInput.required = false;
    }
  }

  resetPicker() {
    this.pickerTarget.value = '';
    this.pickerSingle.value = '';
    this.pickerInput.value = '';
  }

  pickerInputOrDefault() {
    if (this.pickerInput) {
      return this.pickerInput;
    }

    let pickerInput = this.inputParentTarget.querySelector('input.form-control.input');
    return pickerInput || this.pickerSingle;
  }
}
