import Flatpickr from 'stimulus-flatpickr';
import moment from 'moment';

export default class extends Flatpickr {
  connect() {
    this.config = {
      ...this.config,
      enableTime: true,
      noCalendar: true,
      altFormat: 'h:i K',
      dateFormat: 'h:i K',
      altInput: true
    };

    super.connect();

    this.maybeFormatCurrentValue();
  }

  maybeFormatCurrentValue() {
    const format = this.data.get('customFormat')
    const value = this.data.get('currentValue')
    if (format && value != '') {
      const date = moment(value, format)
      this.valueUpdate([date]);
    }
  }

  valueUpdate(date, dateStr, instance) {
    const format = this.data.get('customFormat')
    if (format) {
      this.inputTarget.value = moment(date[0]).format(format);
    }
  }
}
