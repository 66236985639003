import { Controller } from 'stimulus';
import tippy from 'tippy.js';
import { animateCSS } from '~/animation';

export default class extends Controller {
  static targets = [ 'button', 'overlay' ];

  initialize() {
    document.addEventListener(
      "stimulus-reflex:finalize",
      this.refreshTippy.bind(this),
      true
    );
  }

  connect() {
    this.initializeTippy();
    this.overlayTarget.classList.add('hidden');
  }

  initializeTippy() {
    this.tippy = tippy(this.buttonTarget, {
      content: this.overlayTarget.innerHTML,
      trigger: "click",
      animation: "shift-away",
      theme: "light",
      allowHTML: true,
      interactive: true,
      hideOnClick: this.hideOnClick,
      placement: this.placement,
      maxWidth: 'none',
      appendTo: this.closestClass || this.form || document.body,
      popperOptions: {
        strategy: this.strategy,
      },
      showOnCreate: this.showOnCreate,
    });
  }

  refreshTippy() {
    this.destroyTippy();
    this.initializeTippy();
  }

  disconnect() {
    this.destroyTippy();
  }

  close() {
    this.tippy.hide();
  }

  destroyTippy() {
    this.tippy.destroy();
  }

  get closestClass() {
    const elementClass = this.data.get('closestClass');
    if (elementClass && elementClass != "") {
      return $(this.element).closest(`.${elementClass}`)[0];
    }
  }

  get form () {
    return $(this.element).closest('form')[0];
  }

  get hideOnClick() {
    const hide = this.data.get('hideOnClick');
    if (hide && hide != "toggle") {
      return hide == 'true'
    }

    return hide || true;
  }

  get strategy() {
    return this.data.get('strategy');
  }

  get placement() {
    return this.data.get('placement') || 'bottom';
  }

  get showOnCreate() {
    return this.data.get('showOnCreate') == 'true';
  }

  cancelEvent(e) {
    e.preventDefault();
  }
}
