import { Controller } from 'stimulus';
import ahoy from 'ahoy.js';

export default class extends Controller {
  static values = {
    name: String,
    params: Object
  }

  track() {
    if (this.hasNameValue) {
      ahoy.track(this.nameValue, this.paramsValue);
    }
  }
}

