import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'fakeScroll', 'scroll', 'widthElement', 'offsetElement' ]

  connect() {
    this.syncWidth();
  }

  syncScroll(event) {
    this.scrollTarget.scrollLeft = event.target.scrollLeft;
  }

  syncWidth() {
    const width = this.widthElementTarget.offsetWidth + this.offsetWidth;
    this.fakeScrollTarget.style.width = width + 'px';
  }

  get offsetWidth() {
    return this.hasOffsetElementTarget ? this.offsetElementTarget.offsetWidth : 0;
  }
}
