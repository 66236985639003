import { Controller } from 'stimulus';

export default class extends Controller {
  connect () {
    super.connect();
    this.bulkActions.refreshAllCheckbox();
  }

  get bulkActions() {
    return this.element.closest('[data-controller~=bulk-actions]')["bulk-actions"];
  }
}