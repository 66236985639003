import { Controller } from 'stimulus';
import { visitUrl } from '~/lib/utils/url_utility';

export default class extends Controller {
  navigateToLink(event) {
    event.preventDefault();
    visitUrl(this.href);
  }

  externalNavigateToLink() {
    event.preventDefault();
    visitUrl(this.href, { external: true });
  }

  turboRefreshPage() {
    var url = this.href
    Page.refresh({
      url,
      onlyKeys: [this.refreshKeys]
    });
  }

  get href() {
    return this.data.get('href');
  }

  get refreshKeys() {
    return this.data.get('refresh-keys');
  }
}
