import { Controller } from 'stimulus';
import $ from 'jquery';
import { animateCSS } from '~/animation';

export default class extends Controller {
  static targets = [ 'frame', 'backdrop' ];

  open() {
    this.showFrame();
    this.showBackdrop();
    animateCSS(this.backdropTarget, 'fadeIn');
    animateCSS(this.frameTarget, 'slideInLeft');
  }

  close() {
    const self = this;

    animateCSS(this.backdropTarget, 'fadeOut');
    animateCSS(this.frameTarget, 'slideOutLeft', () => {
      self.hideFrame();
      self.hideBackdrop();
    });
  }

  showBackdrop() {
    $(this.backdropTarget).show();
  }

  hideBackdrop() {
    $(this.backdropTarget).hide();
  }

  showFrame() {
    this.frameTarget.classList.add(
      'Polaris-Frame__Navigation--visible',
      'Polaris-Frame__Navigation--enterActive'
    );
  }

  hideFrame() {
    this.frameTarget.classList.remove(
      'Polaris-Frame__Navigation--visible',
      'Polaris-Frame__Navigation--enterActive'
    );
  }
}
