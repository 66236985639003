import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['select']

  connect() {
    this.filter();
  }

  filter() {
    const url = this.url.replace('selected_value', this.typeValue);
    Page.refresh({
      url,
      onlyKeys: [`select-filter-${this.selectField}`],
      updatePushState: false
    });
  }

  get selectField() {
    return this.data.get('field');
  }

  get typeValue() {
    return this.selectTarget.value;
  }

  get url() {
    return this.data.get('url');
  }
}
