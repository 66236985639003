import { Controller } from 'stimulus';
import Chart from 'chart.js';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['canvas', 'download']

  connect() {
    this.chart = new Chart(this.canvasTarget.getContext('2d'), this.chartData);
  }

  get chartData() {
    const data = JSON.parse(this.data.get('data'));
    if (this.hasDownloadTarget) {
      data.options.animation = {
        onComplete: () => {
          this.downloadTarget.href = this.toImage();
        }
      };
    }

    return data;
  }

  toImage() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = this.chart.ctx.canvas.width;
    canvas.height = this.chart.ctx.canvas.height;
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this.canvasTarget, 0, 0);
    $(this.downloadTarget).removeClass('Polaris-Button--disabled');

    return canvas.toDataURL('image/png');
  }
}
