//import 'core-js/es';

import { Turbo } from '@hotwired/turbo-rails';
window.Turbo = Turbo;

require('@rails/ujs').start();
require('@rails/activestorage').start();

import { Trix } from 'trix';
import 'jquery';

require( '../javascripts/controllers');
require('../javascripts/ahoy')

window.addEventListener('popstate', (event) => {
  Turbo.visit(window.location.href, { action: 'replace' });
});

// Fix Safari NSURLSession WebSocket bug
// Taken from: https://docs.stimulusreflex.com/appendices/troubleshooting.html#safari-nsurlsession-websocket-bug
const originalWebSocketClose = WebSocket.prototype.close
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments)
  }
}

import 'selectize';
import 'chartkick';
import 'chart.js';