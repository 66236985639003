import appendElement from '~/lib/utils/append_element';

export default class {
  constructor({api}) {
    this.api = api;
  }

  checkState(selection) {
    const tag = this.api.selection.findParentTag('SPAN', 'cd-variable-wrap');

    if (tag && tag.dataset) {
      this.inputWrap.classList.remove('hidden');
      this.hideButtonsOnTag();
      this.inputVar.value = tag.dataset.fallback;
    } else {
      this.inputWrap.classList.add('hidden');
      this.showButtons();
    }
    this.currentTag = tag;
  }

  hideButtonsOnTag() {
    const buttons = this.actionButtons;
    buttons && buttons.classList.add('hidden');
  }

  render() {
    return document.createElement('div');
  }

  renderActions() {
    this.inputWrap = document.createElement('div');
    appendElement(this.inputWrap, '.fallback-input');
    const inputVar = $(this.inputWrap).find('input');
    inputVar.on('keyup', () => {
      if (this.currentTag && this.currentTag.dataset) {
        this.currentTag.dataset.fallback = this.inputVar.value;
      }
    });

    this.inputVar = inputVar.get(0);
    return this.inputWrap;
  }

  surround() {
  }

  showButtons() {
    const buttons = this.actionButtons;
    buttons && buttons.classList.remove('hidden');
  }

  get actionButtons() {
    this._actionButtons = this._actionButtons || document.querySelector('.ce-inline-toolbar__buttons');
    return this._actionButtons;
  }

  static get isInline() {
    return true;
  }
}
