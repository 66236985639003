import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    name: String,
    detail: Object
  }

  dispatch(event) {
    this.dispatchEvent(this.nameValue, this.detailValue);
  }
}