import { Controller } from 'stimulus';
import $ from 'jquery';

const selectedClass = 'Polaris-Tabs__Tab--selected';

export default class extends Controller {
  static targets = ['tab', 'button'];

  change(event) {
    event.preventDefault();
    const target = $(event.currentTarget);
    this.showTab(target.data('index'));
    $(this.buttonTargets).removeClass(selectedClass);
    $(target).addClass(selectedClass);
  }

  showTab(index) {
    this.tabTargets.forEach((tab) => {
      const t = $(tab);
      if(t.data('index') === index) {
        t.show();
      } else {
        t.hide();
      }
    });
  }
}
