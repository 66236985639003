import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  update() {
    this.form.requestSubmit();
  }

  get form() {
    return this.element.querySelector('form') || this.element;
  }
}