import { Controller } from 'stimulus';

/* Create custom event to hear when Google API is loaded */
window.dispatchMapsEvent = function(...args) {
  const event = document.createEvent('Events');
  event.initEvent('google-maps-callback', true, true);
  event.args = args;
  window.dispatchEvent(event);
};

export default class extends Controller {
  static targets = [ 'autocomplete' ];

  connect() {
    if (window['google'] !== undefined) {
      this.initAutocomplete();
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteTarget,
      { types: ['geocode'] }
    );

    this.autocomplete.setFields(['address_component']);
    this.autocomplete.addListener('place_changed', () => this.fillInAddress());
  }

  fillInAddress() {
    const address = this.getAddressInfo();

    this.setField(
      'line1',
      [address['route'], address['street_number']].filter((e) => e).join(' ')
    );
    this.setField('city', address['locality']);
    this.setField('state', address['administrative_area_level_1']);
    this.setField('postal_code', address['postal_code']);
    this.setField('country', address['country']);
  }

  getAddressInfo() {
    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
    const values = {};

    const place = this.autocomplete.getPlace();
    for (var i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        values[addressType] = place.address_components[i][componentForm[addressType]];
      }
    }

    return values;
  }

  setField(fieldName, value) {
    this.form
      .find(`input[name*='[${fieldName}]']`)
      .val(value);
  }

  get form() {
    return $(this.element).closest('form');
  }
}
