import { Controller } from 'stimulus';
import ClipboardJS from 'clipboard';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['copy', 'toast'];

  connect() {
    if (this.hasCopyTarget) {
      this.connectCopy();
    }
  }

  connectCopy() {
    const clipboard = new ClipboardJS(this.copyTarget);
    if (this.hasToastTarget) {
      clipboard.on('success', this.showToast.bind(this));
    }
  }

  showToast() {
    const target = $(this.toastTarget);
    target.show();
    setTimeout(() => {
      target.hide();
    }, 1200);
  }
}
