import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    key: String,
    event: String
  };

  dispatch(event) {
    if (this.keyValue && event.key !== this.keyValue) return;

    this.dispatchEvent(this.eventValue);
  }
}