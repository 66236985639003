import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    path: String,
  };

  static targets = [
    "email",
    "firstName",
    "lastName",
    "timeZone",
    "locale",
    "password",
    "inviteCheckbox"
  ];

  connect() {
    this.selectElement = this.timeZoneTarget.querySelector("select");
    if (this.selectElement) {
      $(this.selectElement).selectize();
    }
    this.togglePasswordVisibility();
    this.setDefaultZone();
  }

  setDefaultZone() {
    if (this.selectElement && this.selectElement.selectize) {
      const currentValue = this.selectElement.selectize.getValue();
      if (!currentValue) {
        this.selectElement.selectize.setValue("Mexico City", true);
      }
    }
  }

  togglePasswordVisibility() {
    if (this.inviteCheckboxTarget.checked) {
      this.passwordTarget.classList.remove("hidden");
    } else {
      this.passwordTarget.classList.add("hidden");
    }
  }

  checkEmail() {
    const email = this.emailTarget.value;
    if (email) {
      fetch(`${this.pathValue}?email=${email}`)
        .then(response => response.json())
        .then(data => {
          if (data && data.first_name) {
            this.firstNameTarget.value = data.first_name || "";
            this.lastNameTarget.value = data.last_name || "";
            const timeZoneSelect = this.timeZoneTarget.querySelector("select");
            if (timeZoneSelect && data.time_zone) {
              timeZoneSelect.selectize.setValue(data.time_zone, true);
            }
            const localeSelect = this.localeTarget.querySelector("select");
            if (localeSelect && data.locale) {
              localeSelect.selectize.setValue(data.locale, true);
            }
          } else {
            this.firstNameTarget.value = "";
            this.lastNameTarget.value = "";
            this.timeZoneTarget.querySelector("select").selectize.setValue("Mexico City", true);
            this.localeTarget.querySelector("select").selectize.setValue("es", true);
          }
        });
    }
  }

  togglePassword() {
    this.passwordTarget.classList.toggle("hidden");
  }
}
