import Appsignal from "@appsignal/javascript";
import { plugin as PluginBreadcrumbsConsole } from "@appsignal/plugin-breadcrumbs-console";
import { plugin as PluginBreadcrumbsNetwork } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as PluginPathDecorator } from "@appsignal/plugin-path-decorator";
import { plugin as PluginWindowEvents } from "@appsignal/plugin-window-events";

const appsignal = new Appsignal({
  key: gon.appsignal_frontend_api_key,
});

appsignal.use(PluginBreadcrumbsNetwork({}));
appsignal.use(PluginBreadcrumbsConsole({}));
appsignal.use(PluginPathDecorator({}));
appsignal.use(PluginWindowEvents({}));

export { appsignal };
