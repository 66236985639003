// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import '@stimulus/polyfills';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import StimulusReflex from 'stimulus_reflex';
import { application } from './application';
import applicationController from './application_controller';
import { appsignal } from '~/lib/appsignal';
import { installErrorHandler } from '@appsignal/stimulus';

installErrorHandler(appsignal, application);

const context = require.context('.', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

StimulusReflex.initialize(application, {
  applicationController,
  isolate: true,
  serializeForm: true,
});
StimulusReflex.debug = false;
