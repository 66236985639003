import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'section']

  showForm(event) {
    const value = event.target.value;
    this.maybeShowForm(value)
  }

  maybeShowSection(show) {
    if (show) {
      this.sectionTarget.classList.remove('hidden');
    } else {
      this.sectionTarget.classList.add('hidden');
    }
  }

  maybeShowForm(formName) {
    let showSection = false;
    this.formTargets.forEach((f) => {
      if (f.dataset.transitionKey == formName) {
        showSection = true;
        f.classList.remove('hidden')
        this.changeDisabled(f, false);
      } else {
        f.classList.add('hidden')
        this.changeDisabled(f, true);
      }
    });

    if (this.hasSectionTarget) {
      this.maybeShowSection(showSection);
    }
  }

  changeDisabled(target, disabled) {
    target.querySelectorAll('[required]').forEach((ele) => {
      ele.disabled = disabled;
    });
  }
}
