import { Controller } from 'stimulus';

/* Based on: https://dev.to/jankapunkt/make-text-fit-it-s-parent-size-using-javascript-m40 */

export default class extends Controller {
  static values = {
    minSize: Number,
    maxSize: Number
  }

  connect() {
    this.resizeText(this.element, this.minSizeValue || 12, this.maxSizeValue || 20);
  }

  resizeText(element, minSize = 12, maxSize = 512, step = 1, unit = 'px') {
    let i = minSize;
    let overflow = false;

    const parent = element.parentNode;

    while (!overflow && i < maxSize) {
      element.style.fontSize = `${i}${unit}`;
      overflow = this.isOverflown(parent);
      if (!overflow) {
        i += step;
      }
    }

    // revert to last state where no overflow happened
    element.style.fontSize = `${i - step}${unit}`;
  }

  isOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
    return (scrollWidth > clientWidth) || (scrollHeight > clientHeight);
  }
}