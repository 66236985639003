import { Controller } from 'stimulus';
import { successToast } from '../lib/utils/toast';

export default class extends Controller {
  static targets = ['source'];

  copy(event) {
    event.preventDefault();
    this.sourceTarget.classList.remove('hidden');
    this.sourceTarget.select();
    document.execCommand('copy');
    this.sourceTarget.classList.add('hidden');
    successToast('Copied to clipboard');
  }
}