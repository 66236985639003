import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['inputGroup', 'input', 'inputDefault']

  maybeBlock() {
    if (this.hasInputDefaultTarget) {
      this.inputGroupTarget.checked = true;
    }
  }

  maybeShowIndeterminate() {
    if (this.inputGroupTarget.checked) {
      const icon = this.element.querySelector('.Polaris-Checkbox__Icon');
      const className = 'with-indeterminate';
      if (this.showIndeterminate) {
        icon.classList.add(className);
      } else {
        icon.classList.remove(className);
      }
    }
  }

  toggleGroup() {
    const check = this.checked;
    this.inputTargets.forEach((input) => input.checked = check);
    this.maybeBlock();
    this.maybeShowIndeterminate();
  }

  updateInput() {
    this.inputGroupTarget.checked = this.checkedsLength > 0;
    this.maybeShowIndeterminate();
  }

  get checked() {
    if (this.hasInputDefaultTarget) {
      return this.checkedsLength === 0;
    }
    return this.inputGroupTarget.checked;
  }

  get checkedsLength() {
    return this.inputTargets.filter((input) => input.checked).length;
  }

  get showIndeterminate() {
    return this.checkedsLength !== this.inputTargets.length;
  }
}
