import Marker from '@editorjs/marker';
import Link from '@editorjs/link';
import Delimiter from '@editorjs/delimiter';
import ListTool from '~/lib/editorjs/list_tool';
import QuoteTool from '~/lib/editorjs/quote_tool';
import ImageTool from '~/lib/editorjs/image_tool';
import HeaderTool from '~/lib/editorjs/header_tool';
import TableTool from '~/lib/editorjs/table_tool';
import Paragraph from '~/lib/editorjs/paragraph_tool';
import VariableTool from '~/lib/editorjs/variable_tool';
import SurroundTag from '~/lib/editorjs/surround_tag';

function enableInline(tool, options = {}) {
  options.class = tool;
  options.inlineToolbar = true;
  return options;
}

export default function configure(base) {
  return {
    data: base.initialData,
    holder: base.editorTarget,
    onChange: base.change.bind(base),
    tools: {
      delimiter: Delimiter,
      paragraph: enableInline(Paragraph),
      header: enableInline(HeaderTool),
      image: {
        class: ImageTool,
        config: {
          uploader: base.uploaderConfig
        }
      },
      list: enableInline(ListTool),
      link: {
        class: Link,
        config: {
          endpoint: base.linkFetch
        }
      },
      marker: Marker,
      quote: enableInline(QuoteTool),
      table: enableInline(TableTool),
      variable: VariableTool
    }
  };
}

((proxied) => {
  document.execCommand = function(command) {
    const surround = new SurroundTag(command);
    if (surround.available) {
      surround.toggle();
    } else {
      return proxied.apply(this, arguments);
    }
  };
})(document.execCommand);
