import { Controller } from 'stimulus';
import { removeClass, addClass } from '~/lib/utils/class_fns';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['choice', 'input', 'inputParent']

  updateChoice(event) {
    this.inputTarget.value = "";
    if (event.target.value == "work") {
      removeClass(this.inputParentTarget, "hidden");
      this.inputTarget.required = true;
    } else {
      addClass(this.inputParentTarget, "hidden");
      this.inputTarget.required = false;
    }
  }
}
