import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['value'];

  updateCarrier(_event) {
    this.stimulateSerialized(
      'CarrierAccountCredentialsReflex#update_carrier',
      this.valueTarget.value
    );
  }
}
