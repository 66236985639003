import { Controller } from 'stimulus';
import ace from 'brace';
import 'brace/mode/liquid';
import 'brace/mode/css';
import 'brace/mode/json';
import 'brace/ext/searchbox';
import 'brace/theme/crimson_editor';

export default class extends Controller {
  static targets = ['editor', 'input'];

  static values = {
    contentType: String
  }

  connect() {
    this.editor = ace.edit(this.editorTarget);
    this.editor.setOptions({
      selectionStyle: 'text'
    });
    this.editor.getSession().setMode(this.mode);
    this.editor.setTheme('ace/theme/crimson_editor');
    this.editor.getSession().setTabSize(2);
    this.editor.getSession().setUseSoftTabs(true);
    this.editor.getSession().setUseWrapMode(true);
    this.editor.resize();
    this.editor.setValue(this.inputTarget.value);
    this.editor.clearSelection();
    this.editor.selection.moveCursorFileStart();
  }

  updateInput(_event) {
    this.inputTarget.value = this.editor.getValue();
  }

  disconnect() {
    this.editor.destroy();
  }

  get mode() {
    switch (this.contentTypeValue) {
      case 'css':
        return 'ace/mode/css';
      case 'json':
        return 'ace/mode/json';
      default:
        return 'ace/mode/liquid';
    }
  }
}