import ApplicationController from './application_controller';

export default class extends ApplicationController {
  completed() {
    const event = document.createEvent('Event');
    event.initEvent('ticket-update', true, true);
    this.dispatchEvent(event);
  }

  updateStatus(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const value = target.dataset.status;
    const current = target.dataset.currentStatus;

    this
      .stimulate('TicketActionsReflex#update_status', value)
      .then(this.completed.bind(target));
  }
}
