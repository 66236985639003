import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkboxTouch', 'dataTouch', 'renderBody']

  changeAssetTemplate(e) {
    this.forceCheckAndDisabledTouch();
    this.dataTouchTarget.remove();
    this.renderBodyTarget.value = '';
  }

  forceCheckAndDisabledTouch() {
    const check = this.checkboxTouchTarget;
    check.checked = true;
    check.disabled = true;
  }
}
