import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'dateForm', 'dateInput', 'startInput', 'endInput' ];

  changeDay(event) {
    const value = event.target.value;
    if (value) {
      this.submitTableDateForm(value);
    }
  }

  changeStart(event) {
    const value = this.startInputTarget.value;
    if (value == "") {
      this.endInputTarget.selectize.clear();
      this.endInputTarget.selectize.disable();
      return;
    }
    this.updateEndInput(value);
  }

  submitTableDateForm(date) {
    this.dateInputTarget.value = date;
    this.dateFormTarget.requestSubmit();
  }

  updateEndInput(value) {
    let options = this.endInputTarget.selectize.options;
    let keyOptions = Object.keys(options);
    let indexFrom = keyOptions.indexOf(value);

    keyOptions.forEach((key, index) => {
      options[key].disabled = index <= indexFrom;
    });
    this.endInputTarget.selectize.clear();
    this.endInputTarget.selectize.enable();
    this.updateSelect(this.endInputTarget);
  }

  updateSelect(element) {
    element.selectize.renderCache = {};
    element.selectize.refreshOptions();
  }
}
