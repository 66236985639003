import { Controller } from 'stimulus';
import { removeClass, addClass } from '~/lib/utils/class_fns';

export default class extends Controller {
  static targets = ['spinner', 'disclosure'];

  connect() {
    this.element.addEventListener('loading_button:init', this.onInit.bind(this));
    this.element.addEventListener('loading_button:end', this.onEnd.bind(this));
  }

  displaySpinner(event) {
    this.disabledElement(event);
    this.spinnerTarget.classList.remove('hidden');
  }

  disabledElement(event) {
    event.currentTarget.classList.add('Polaris-Button--disabled');
  }

  hideDisclosure() {
    if (this.hasDisclosureTarget) {
      addClass(this.disclosureTarget, 'hidden')
    }
  }

  onInit(event) {
    addClass(this.element, 'Polaris-Button--disabled');
    addClass(this.element, 'Polaris-Button--loading');
    removeClass(this.spinnerTarget, 'hidden');
  }

  onEnd() {
    removeClass(this.element, 'Polaris-Button--disabled');
    removeClass(this.element, 'Polaris-Button--loading');
    addClass(this.spinnerTarget, 'hidden');
  }
}
