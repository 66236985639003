import Quote from '@editorjs/quote';
import mergeVariableTool from '~/lib/editorjs/variable_tool/merge_variable_tool';

const QuoteTool = mergeVariableTool(Quote);
export default class extends QuoteTool {
  render() {
    this.wrapper = super.render();
    return this.wrapper;
  }

  rendered() {
    if (this.wrapper) {
      this.wrapper.childNodes.forEach((child) => {
        this.parseTemplate(child.innerHTML).then((res) => {
          this.setRenderTemplate(child, res);
        });
      });
    }
  }
}
