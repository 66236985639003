import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [ 'svg' ];

  connect() {
    this.removeListeners();
  }

  removeListeners() {
    document.addEventListener('page:after-node-removed', (event) => {
      $(event.data).remove();
    });
  }
}
