import { Controller } from 'stimulus';
import Pickr from '@simonwep/pickr';

export default class extends Controller {
  static targets = ['input', 'picker', 'label'];

  connect() {
    const initialColor = this.inputTarget.value;

    this.pickr = Pickr.create({
      el: this.pickerTarget,
      default: initialColor,
      theme: 'nano',
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          rgba: true,
          input: true,
          clear: true,
          save: true
        }
      }
    });

    this.pickr.on('save', (color) => {
      if (color) {
        this.inputTarget.value = color.toHEXA().toString();
        this.labelTarget.innerHTML = color.toHEXA().toString();
      } else {
        this.inputTarget.value = '';
      }
      this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }));
      this.pickr.hide();
    });
  }

  disconnect() {
    this.pickr.destroyAndRemove();
  }
}