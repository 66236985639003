import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { errorToast } from '~/lib/utils/toast';
import { appsignal } from '~/lib/appsignal';

export default class extends Controller {
  initialize() {
    this.element[this.identifier] = this;
  }

  connect() {
    StimulusReflex.register(this);
    this.element[this.identifier] = this;
  }

  beforeReflex(element, reflex) {
    document.body.classList.add('wait');
  }

  afterReflex(element, reflex) {
    document.body.classList.remove('wait');
    this.restoreFocus();
  }

  reflexSuccess(element, reflex, error) {
    // show success message etc...
  }

  reflexError(element, reflex, error) {
    appsignal.sendError(error);
    errorToast(gon.reflex_error_message);
  }

  restoreFocus() {
    const focusElement = this.element.querySelector('[autofocus]');
    if (focusElement) {
      focusElement.focus();
      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value;
      focusElement.value = '';
      focusElement.value = value;
    }
  }

  stimulateSerialized(reflexName, data = null) {
    if (data === null) {
      this.stimulate(reflexName, this.element, { serializeForm: true });
    } else {
      this.stimulate(reflexName, this.element, { serializeForm: true }, data);
    }
  }

  dispatchEvent(type, detail, source) {
    let event;

    if (typeof CustomEvent === 'function') {
      // Modern browsers
      event = new CustomEvent(type, {
        bubbles: true,
        detail: detail,
      });
    } else {
      // IE
      event = document.createEvent('CustomEvent');
      event.initCustomEvent(type, true, false, detail);
    }

    if (source) {
      source.dispatchEvent(event);
    } else {
      document.dispatchEvent(event);
    }
  }

  isActionCableConnectionOpen() {
    return document.body.classList.contains('stimulus-reflex-connected');
  }
}
