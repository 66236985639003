import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'element' ];

  show() {
    this.elementTarget.classList.remove(this.hiddenClass);
  }

  hide() {
    this.elementTarget.classList.add(this.hiddenClass);
  }

  get hiddenClass() {
    return this.element.dataset.hiddenClass || 'hidden';
  }
}
