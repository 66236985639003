import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['imageId', 'imageChecksum', 'thumbnail', 'clearButton'];

  connect() {
    // Hide thumbnail if there's no image
    if (this.hasThumbnailTarget && this.thumbnailTarget.children.length == 0) {
      this.hideThumbnail();
    }
  }

  removeImage() {
    if (this.hasImageIdTarget) {
      this.imageIdTarget.value = '';
    }
    if (this.hasImageChecksumTarget) {
      this.imageChecksumTarget.value = '';
    }
    this.hideThumbnail();
    this.dispatchChange();
  }
  
  handleDone(event) {
    // Set fields
    if (this.hasImageIdTarget) {
      this.imageIdTarget.value = event.detail.imageId;
    }

    if (this.hasImageChecksumTarget) {
      this.imageChecksumTarget.value = event.detail.imageChecksum;
    }

    // Set placeholder
    this.setThumbnail(event.detail.thumbnail);
    this.dispatchChange();
  }
  
  setThumbnail(imageSrc) {
    this.thumbnailTarget.innerHTML = `<img src="${imageSrc}" class="object-contain w-full h-36" />`;
    this.showThumbnail();
  }

  showThumbnail() {
    this.thumbnailTarget.classList.remove('hidden');
    this.clearButtonTarget.classList.remove('hidden');
  }

  hideThumbnail() {
    this.thumbnailTarget.classList.add('hidden');
    this.clearButtonTarget.classList.add('hidden');
  }

  dispatchChange() {
    const event = new Event('change', { bubbles: true });

    if (this.hasImageIdTarget) {
      this.imageIdTarget.dispatchEvent(event);
    }

    if (this.hasImageChecksumTarget) {
      this.imageChecksumTarget.dispatchEvent(event);
    }
  }
}