import Controller from './content_loader_controller';

export default class extends Controller {
  connect() {
    this.intervalId = setInterval(() => {
      this.load();
    }, this.timeout);
  }

  disconnect() {
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  get timeout() {
    const value = this.data.get('timeout');
    return value ? parseInt(value) : 600000;
  }
}
