import Rails from '@rails/ujs';

// Each method works differently when sending data,
// the only common thing is when everything goes by query string parameters.
export default function sendBeacon(url, data = {}) {
  data['authenticity_token'] = Rails.csrfToken();
  const sendUrl = `${url}?${$.param(data)}`;

  if (window.navigator && window.navigator.sendBeacon) {
    return window.navigator.sendBeacon(sendUrl);
  }

  if (window.fetch) {
    return window.fetch(sendUrl, { method: 'post', keepalive: true });
  }

  const xhr = (window.XMLHttpRequest) ? new window.XMLHttpRequest() : new window.ActiveXObject('Microsoft.XMLHTTP');
  xhr.open('POST', sendUrl, false);
  xhr.withCredentials = true;
  xhr.setRequestHeader('Accept', '*/*');

  try{
    xhr.send();
  }catch (error) {
    return false;
  }

  return true;
}
