import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'reference' ];

  findElement(currentElement, key, value) {
    let parent = currentElement.parentElement;

    for(let i=0; i<5; i++) {
      if (parent.dataset[key] == value) {
        return parent;
      }
      parent = parent.parentElement;
    }
  }

  hide() {
    this.tippy.hide();
  }

  referenceContent() {
    const overlay = this.findElement(this.reference, 'controller', 'popover');
    if (!overlay) {
      return ;
    }

    return overlay.lastElementChild;
  }

  refreshContent() {
    const content = this.referenceContent();
    if (!content) {
      return ;
    }

    this.tippy.setContent(content.innerHTML);
  }

  refreshContentNextTick() {
    setTimeout(() => { this.refreshContent() }, 100);
  }

  get reference() {
    return this.tippy.reference;
  }

  get rootTarget() {
    if (this.referenceRoot || !this.hasReferenceTarget) {
      return this.referenceRoot;
    }

    this.referenceRoot = this.findElement(this.referenceTarget, 'tippyRoot', '');
    return this.referenceRoot;
  }

  get tippy() {
    return this.rootTarget._tippy;
  }
}
