// Animates an element using animate.css
//
// animateCSS('.my-element', 'bounce')
//
// or
//
// animateCSS('.my-element', 'bounce', function() {
//   // Do something after animation
// })
export function animateCSS(node, animationName, callback) {
  const $node = $(node);

  function handleAnimationEnd() {
    $node.toggleClass(`animated ${animationName}`);
    node.removeEventListener('animationend', handleAnimationEnd);

    if (typeof callback === 'function') {
      callback();
    }
  }

  $node.toggleClass(`animated ${animationName}`);
  node.addEventListener('animationend', handleAnimationEnd);
}
