import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['loading'];

  disconnect() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.close();
      window.HubSpotConversations.widget.remove();
    }
  }

  openSupport() {
    this.loading();
    if (window.HubSpotConversations) {
      // If Turbolinks has navigated, need to restore CSS element
      this.restoreCSS();
      this.openChat();
      this.loaded();
    } else {
      this.loadHubspot();
    }
  }

  loading() {
    this.loadingElement.siblings().hide();
    this.loadingElement.show();
  }

  loaded() {
    this.loadingElement.siblings().show();
    this.loadingElement.hide();
  }

  restoreCSS() {
    const html = window.localStorage.getItem('hubspot-css');
    const script = document.createElement("style");
    script.id = 'hs-script-loader';
    script.innerHTML = html;
    document.body.appendChild(script);
  }

  saveCSS() {
    const html = document.body.getElementsByTagName('style')[0].innerHTML;
    window.localStorage.setItem('hubspot-css', html);
  }
 
  loadHubspot() {
    window.hsConversationsOnReady = [() => this.onHubSpotLoaded()];

    const script = document.createElement("script");
    script.id = 'hs-script-loader';
    script.type = "text/javascript";
    script.src = `//js.hs-scripts.com/${this.hubSpotId}.js`;
    document.body.appendChild(script);
  }

  onHubSpotLoaded() {
    this.saveCSS();
    this.openChat();
    this.loaded();
  }

  openChat() {
    window.hsConversationsSettings = {
      loadImmediately: true,
      enableWidgetCookieBanner: true
    }

    if (this.userIdentified === undefined) {
      this.userIdentified = true;
      this.identifyUser();
    }

    if (!window.HubSpotConversations.widget.status().loaded) {
      window.HubSpotConversations.widget.load({ widgetOpen: true });
    }

    window.HubSpotConversations.widget.open();
  }

  // Based on: https://community.hubspot.com/t5/APIs-Integrations/How-to-identify-a-contact-in-the-chat/m-p/272830#M24773
  identifyUser() {
    let _hsq = window._hsq = window._hsq || [];
    _hsq.push(["identify", {
      firstname: this.firstName,
      lastname: this.lastName,
    	email: this.email,
    }]);
    _hsq.push(['trackPageView']);
    window.HubSpotConversations.widget.refresh();
  }

  get hubSpotId() {
    return this.data.get("hubspot-id");
  }

  get email() {
    return this.data.get("email");
  }

  get firstName() {
    return this.data.get("first-name");
  }

  get lastName() {
    return this.data.get("last-name");
  }

  get loadingElement() {
    return $(this.loadingTarget);
  }
}