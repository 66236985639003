import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'label' ];

  static values = {
    ignoreReferrer: Boolean
  };

  connect() {
    const label = this.data.get('label');
    if (label != null && this.referrer.length > 0 ) {
      this.labelTarget.innerHTML = label;
    }
  }

  navigate(e) {
    if (this.referrer.length > 0 && !this.ignoreReferrerValue) {
      e.preventDefault();
      window.history.back();
    }
  }

  get referrer() {
    return document.referrer;
  }
}
