import List from '@editorjs/list';
import mergeVariableTool from '~/lib/editorjs/variable_tool/merge_variable_tool';

const ListTool = mergeVariableTool(List);
export default class extends ListTool {
  rendered() {
    const wrapper = this._elements.wrapper;
    if (wrapper && wrapper.innerHTML.trim() !== "") {
      this.parseTemplate(wrapper.innerHTML).then((res) => {
        this.setRenderTemplate(wrapper, res, false);
      });
    }
  }

  get data() {
    this._data.items = [];
    const items = this._elements.wrapper.cloneNode(true).querySelectorAll(`.${this.CSS.item}`);
    items.forEach((item) => {
      const value = item.innerHTML.replace('<br>', ' ').trim();

      if (value) {
        const content = this.tagToText(item);
        this._data.items.push(content.innerHTML);
      }

    });

    return this._data;
  }

  set data(data) {
    super.data = data;
  }
}
