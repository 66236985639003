import { navigator } from '@hotwired/turbo';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hiddenInputRedirect']

  connect() {
    this.maybeSetCustomRedirectoToReferer();
  }

  maybeSetCustomRedirectoToReferer() {
    const referrer = navigator.currentVisit && navigator.currentVisit.referrer || {};
    if (referrer && referrer.href) {
      this.hiddenInputRedirectTarget.value = referrer.href;
    }
  }
}
