import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['form', 'submit'];

  disableButton() {
    $(this.submitTarget).addClass('Polaris-Button--disabled');
  }

  enableButton() {
    $(this.submitTarget).removeClass('Polaris-Button--disabled');
  }

  validateSubmit() {
    if (this.formTarget.checkValidity()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  scrollTop() {
    $('html').animate({ scrollTop: 0 }, 500);
  }

  submit() {
    if (this.formTarget.checkValidity && this.formTarget.checkValidity()) {
      this.scrollTop();
      this.disableButton();
    }
  }
}
