import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ['input']

  openGoogleMaps() {
    let address = ''
    let googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query='
    address = this.inputTargets.map((input) => input.value).join(' ');
    googleMapsUrl += address;
    window.open(googleMapsUrl, '_blank');
  }
}