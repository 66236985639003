import ApplicationController from './application_controller';
import Rails from '@rails/ujs';
import _ from 'lodash';
import $ from 'jquery';

export default class extends ApplicationController {
  static targets = [ 'form', 'input', 'sheetContainer' ];
  static values = {
    refreshKeys: Array,
    filterSheetKey: String,
    fullRefresh: Boolean
  };

  initialize() {
    this.regexpFilter = /^q\[|^utf8/;
    this.query = _.debounce(this.query, 500);
  }

  query(event) {
    this.maybeRefreshReflexRootKeys(event);
    const form = this.form();
    Rails.fire(form, 'submit');
  }

  queryViaUrl(event) {
    event.preventDefault();
    const form = $(this.form());
    const params = this.filterKeys(form);
    const url = `${form.attr('action')}?${$.param(params)}`;
    let opts = { url: decodeURIComponent(url) };
    if (!this.fullRefreshValue) {
      opts.onlyKeys = this.onlyKeys(event);
    }

    Page.refresh(opts);
  }

  maybeRefreshReflexRootKeys(event) {
    if (!this.hasFormTarget || !this.formTarget.dataset.reflexRoot) {
      return;
    }

    const keys = this.refreshKeysValue;
    if (!this.isEventFromSheet(event)) {
      keys.push(this.filterSheetKeyValue);
    }
    const reflexRoot = keys.map(k => `#${k}`).join(", ");
    this.formTarget.dataset.reflexRoot = reflexRoot;
  }

  filterKeys(form) {
    const paramsFilter = [];
    const params = form.serializeArray().filter((param) => {
      const key = `${param.name}${param.value}`;
      const filterName = this.regexpFilter.test(param.name);
      if (filterName && param.value !== '' && !paramsFilter.includes(key)) {
        paramsFilter.push(key);
        return true;
      }
    });

    return params;
  }

  form() {
    return this.element.getElementsByTagName('form')[0];
  }

  isEventFromSheet(event) {
    return this.hasSheetContainerTarget && event.target.dataset && (
      $.contains(this.sheetContainerTarget, event.target) || event.target.dataset.sheet
    );
  }
}
